import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const HomePage = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('HomePage rendered. CurrentUser:', currentUser);
  }, [currentUser]);

  const handleLogin = () => {
    console.log('Navigating to login page');
    navigate('/login');
  };

  const handleSignup = () => {
    console.log('Navigating to signup page');
    navigate('/signup');
  };
  
  const handleLogout = async () => {
    try {
      console.log('Attempting to log out');
      await logout();
      console.log('Logout successful, navigating to home page');
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDashboardClick = () => {
    console.log('Dashboard button clicked. CurrentUser:', currentUser);
    if (currentUser) {
      console.log('Attempting to navigate to user dashboard');
      navigate('/user-dashboard', { replace: true });
      console.log('Navigation function called. Current location:', window.location.pathname);
    } else {
      console.error('No current user found when trying to access dashboard');
    }
  };

  return (
    <section className="relative flex items-center justify-center min-h-screen overflow-hidden bg-gradient-to-br from-blue-800 to-blue-400">
      <svg className="absolute inset-0 w-full h-full opacity-30" xmlns="http://www.w3.org/2000/svg">
        <filter id="noise">
          <feTurbulence 
            type="fractalNoise" 
            baseFrequency="0.60" 
            numOctaves="3" 
            stitchTiles="stitch"
          />
          <feColorMatrix type="saturate" values="0"/>
        </filter>
        <rect width="100%" height="100%" filter="url(#noise)"/>
      </svg>
      <div className="relative z-20 max-w-3xl w-full text-center px-4 py-12">
        <div className="inline-flex border border-white/10 px-3 py-1 rounded-lg tracking-tight text-white mb-6 text-sm">
          Welcome to Submittt
        </div>
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tighter text-white mb-6">
          Find Opportunities for Your Music
        </h1>
        <p className="text-lg sm:text-xl text-blue-100 tracking-tight mb-8 max-w-xl mx-auto">
          Bypass Gatekeepers, Find Opportunities, and take your music to the next level.
        </p>
        {currentUser ? (
          <>
            <p className="text-white mb-4">Welcome, {currentUser.displayName || currentUser.email}!</p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={handleDashboardClick}
                className="bg-white text-blue-800 px-6 py-3 rounded-lg font-semibold hover:bg-blue-100 transition duration-300"
              >
                Go to Dashboard
              </button>
              <button
                onClick={handleLogout}
                className="bg-transparent border border-white text-white px-6 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300"
              >
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="text-white mb-4">Please log in or sign up to continue.</p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={handleLogin}
                className="bg-white text-blue-800 px-6 py-3 rounded-lg font-semibold hover:bg-blue-100 transition duration-300"
              >
                Login
              </button>
              <button
                onClick={handleSignup}
                className="bg-transparent border border-white text-white px-6 py-3 rounded-lg font-semibold hover:bg-white/10 transition duration-300"
              >
                Sign Up
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default HomePage;