import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import HomePage from './Components/Home/HomePage';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import UserDashboard from './Components/Dashboard/UserDashboard';
import AdminDashboard from './Components/Dashboard/AdminDashboard';
import ProducersFinder from './Components/ProducersFinder/ProducersFinder';
import SharedOpportunityView from './Components/Opportunity/SharedOpportunityView';
import ShortLinkRedirect from './Components/Opportunity/ShortLinkRedirect';
import './App.css';

function PrivateRoute({ children, adminOnly = false }) {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && currentUser.role !== 'admin') {
    return <Navigate to="/user-dashboard" />;
  }

  // Allow admins to access user dashboard
  if (currentUser.role === 'admin' && !adminOnly) {
    return children;
  }

  return children;
}

function PublicRoute({ children }) {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (currentUser) {
    return <Navigate to={currentUser.role === 'admin' ? '/admin-dashboard' : '/user-dashboard'} />;
  }

  return children;
}

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route 
                path="/" 
                element={
                  <PublicRoute>
                    <HomePage />
                  </PublicRoute>
                } 
              />
              <Route 
                path="/login" 
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                } 
              />
              <Route 
                path="/signup" 
                element={
                  <PublicRoute>
                    <Signup />
                  </PublicRoute>
                } 
              />
              <Route 
                path="/user-dashboard" 
                element={
                  <PrivateRoute>
                    <UserDashboard />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/admin-dashboard" 
                element={
                  <PrivateRoute adminOnly={true}>
                    <AdminDashboard />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/producers-finder" 
                element={
                  <PrivateRoute>
                    <ProducersFinder />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/opportunity/:id" 
                element={<SharedOpportunityView />} 
              />
              <Route 
                path="/s/:shortId" 
                element={<ShortLinkRedirect />} 
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;