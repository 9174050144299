import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase';
import { FiEdit2, FiTrash2, FiImage } from 'react-icons/fi';

const OpportunitiesPanel = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [newOpportunity, setNewOpportunity] = useState({ 
    title: '', 
    description: '', 
    endDate: '',
    status: 'open',
    imageUrl: ''
  });
  const [editingOpportunity, setEditingOpportunity] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const fetchOpportunities = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'opportunities'));
      const opportunityList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOpportunities(opportunityList);
    } catch (error) {
      console.error('Error fetching opportunities:', error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const uploadImage = async () => {
    if (!imageFile) return null;
    const storage = getStorage();
    const imageRef = ref(storage, `opportunities/${imageFile.name}`);
    await uploadBytes(imageRef, imageFile);
    return getDownloadURL(imageRef);
  };

  const addOpportunity = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = newOpportunity.imageUrl;
      if (imageFile) {
        imageUrl = await uploadImage();
      }
      
      if (editingOpportunity) {
        await updateOpportunity(imageUrl);
      } else {
        await addDoc(collection(db, 'opportunities'), {
          ...newOpportunity,
          imageUrl,
          createdAt: serverTimestamp()
        });
      }
      setNewOpportunity({ title: '', description: '', endDate: '', status: 'open', imageUrl: '' });
      setEditingOpportunity(null);
      setImageFile(null);
      fetchOpportunities();
    } catch (error) {
      console.error('Error adding/updating opportunity:', error);
      alert(`Failed to ${editingOpportunity ? 'update' : 'add'} opportunity: ${error.message}`);
    }
  };

  const updateOpportunity = async (imageUrl) => {
    try {
      await updateDoc(doc(db, 'opportunities', editingOpportunity.id), {
        title: newOpportunity.title,
        description: newOpportunity.description,
        endDate: newOpportunity.endDate,
        status: newOpportunity.status,
        imageUrl: imageUrl || newOpportunity.imageUrl
      });
      setEditingOpportunity(null);
      setNewOpportunity({ title: '', description: '', endDate: '', status: 'open', imageUrl: '' });
      fetchOpportunities();
    } catch (error) {
      console.error('Error updating opportunity:', error);
      alert(`Failed to update opportunity: ${error.message}`);
    }
  };

  const deleteOpportunity = async (id) => {
    if (window.confirm('Are you sure you want to delete this opportunity?')) {
      try {
        await deleteDoc(doc(db, 'opportunities', id));
        fetchOpportunities();
      } catch (error) {
        console.error('Error deleting opportunity:', error);
        alert(`Failed to delete opportunity: ${error.message}`);
      }
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-8 text-gray-800">Opportunities</h2>
      <div className="space-y-6">
        {opportunities.map(opportunity => (
          <div key={opportunity.id} className="bg-white shadow-md rounded-lg p-6">
            {opportunity.imageUrl && (
              <img src={opportunity.imageUrl} alt={opportunity.title} className="w-full h-48 object-cover rounded-lg mb-4" />
            )}
            <h3 className="text-xl font-semibold text-gray-800">{opportunity.title}</h3>
            <p className="mt-2 text-gray-600">{opportunity.description}</p>
            <p className="mt-2 text-gray-600">End Date: {opportunity.endDate}</p>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-2 ${
              opportunity.status === 'open' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}>
              {opportunity.status}
            </span>
            <div className="mt-4 flex items-center gap-2">
              <button
                onClick={() => {
                  setEditingOpportunity(opportunity);
                  setNewOpportunity({
                    title: opportunity.title,
                    description: opportunity.description,
                    endDate: opportunity.endDate,
                    status: opportunity.status,
                    imageUrl: opportunity.imageUrl
                  });
                }}
                className="inline-flex items-center px-3 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded-md transition duration-150"
              >
                <FiEdit2 className="mr-2 h-4 w-4" /> Edit
              </button>
              <button
                onClick={() => deleteOpportunity(opportunity.id)}
                className="inline-flex items-center px-3 py-2 bg-red-500 text-white hover:bg-red-600 rounded-md transition duration-150"
              >
                <FiTrash2 className="mr-2 h-4 w-4" /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={addOpportunity} className="mt-12 bg-white shadow-md rounded-lg p-6">
        <h3 className="text-2xl font-bold mb-6 text-gray-800">
          {editingOpportunity ? 'Edit Opportunity' : 'Add New Opportunity'}
        </h3>
        <div className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium mb-1 text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={newOpportunity.title}
              onChange={(e) => setNewOpportunity({...newOpportunity, title: e.target.value})}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium mb-1 text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              value={newOpportunity.description}
              onChange={(e) => setNewOpportunity({...newOpportunity, description: e.target.value})}
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium mb-1 text-gray-700">
              End Date
            </label>
            <input
              type="date"
              id="endDate"
              value={newOpportunity.endDate}
              onChange={(e) => setNewOpportunity({...newOpportunity, endDate: e.target.value})}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            />
          </div>
          <div>
            <label htmlFor="status" className="block text-sm font-medium mb-1 text-gray-700">
              Status
            </label>
            <select
              id="status"
              value={newOpportunity.status}
              onChange={(e) => setNewOpportunity({...newOpportunity, status: e.target.value})}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            >
              <option value="open">Open</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          <div>
            <label htmlFor="image" className="block text-sm font-medium mb-1 text-gray-700">
              Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              accept="image/*"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
            />
          </div>
          {(newOpportunity.imageUrl || imageFile) && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {imageFile ? 'New image selected' : 'Current image'}:
              </p>
              {imageFile ? (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="Selected"
                  className="mt-2 w-full h-48 object-cover rounded-lg"
                />
              ) : (
                <img
                  src={newOpportunity.imageUrl}
                  alt="Current"
                  className="mt-2 w-full h-48 object-cover rounded-lg"
                />
              )}
            </div>
          )}
        </div>
        <div className="mt-8">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-500 text-white hover:bg-blue-600 rounded-md transition duration-150 font-medium"
          >
            {editingOpportunity ? 'Update Opportunity' : 'Add Opportunity'}
          </button>
          {editingOpportunity && (
            <button
              type="button"
              onClick={() => {
                setEditingOpportunity(null);
                setNewOpportunity({ title: '', description: '', endDate: '', status: 'open', imageUrl: '' });
                setImageFile(null);
              }}
              className="ml-4 px-6 py-3 bg-gray-300 text-gray-800 hover:bg-gray-400 rounded-md transition duration-150 font-medium"
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default OpportunitiesPanel;