import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  onAuthStateChanged,
  EmailAuthProvider,
  linkWithCredential,
  applyActionCode,
  updateProfile,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const googleProvider = new GoogleAuthProvider();

  function normalizeEmail(email) {
    return email.toLowerCase().trim();
  }

  async function getUserByEmail(email) {
    console.log('Searching for user with email:', email);
    const normalizedEmail = normalizeEmail(email);
    const q = query(collection(db, 'users'), where('email', '==', normalizedEmail));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No user found with this email in Firestore');
      return null;
    }

    const userDoc = querySnapshot.docs[0];
    console.log('User found in Firestore:', userDoc.id, userDoc.data());
    return { id: userDoc.id, ...userDoc.data() };
  }

  async function getUserRole(uid) {
    console.log('Fetching user role for UID:', uid);
    const userDoc = await getDoc(doc(db, 'users', uid));

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const role = userData.role || 'user';
      console.log('User role fetched:', role);
      return role;
    } else {
      console.log('User document not found in Firestore, creating with default role');
      await setDoc(doc(db, 'users', uid), {
        email: auth.currentUser.email,
        role: 'user',
        authMethods: ['password'],
      });
      return 'user';
    }
  }

  function startRoleListener(uid) {
    return onSnapshot(doc(db, 'users', uid), (doc) => {
      if (doc.exists()) {
        const newRole = doc.data().role;
        console.log('Role updated in database:', newRole);
        setCurrentUser((prevUser) => ({
          ...prevUser,
          role: newRole,
        }));
      } else {
        console.log('User document does not exist');
      }
    }, (error) => {
      console.error('Error in role listener:', error);
    });
  }

  async function signup(email, password, role = 'user') {
    try {
      const normalizedEmail = normalizeEmail(email);
      const existingUser = await getUserByEmail(normalizedEmail);

      if (existingUser) {
        throw new Error('An account with this email already exists. Please use a different email or log in.');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, normalizedEmail, password);
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: normalizedEmail,
        role: role,
        authMethods: ['password'],
      });
      await sendEmailVerification(userCredential.user);
      return { ...userCredential, role };
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async function login(email, password) {
    try {
      const normalizedEmail = normalizeEmail(email);
      console.log('Attempting login for email:', normalizedEmail);
      const existingUser = await getUserByEmail(normalizedEmail);

      if (!existingUser) {
        console.log('No user found in Firestore for email:', normalizedEmail);
        throw new Error('No account found with this email. Please sign up first.');
      }

      const userCredential = await signInWithEmailAndPassword(auth, normalizedEmail, password);
      console.log('Successfully authenticated with Firebase');

      const updatedRole = await getUserRole(userCredential.user.uid);
      console.log('User logged in with role:', updatedRole);
      return { ...userCredential, role: updatedRole };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async function signInWithGoogle() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const normalizedEmail = normalizeEmail(user.email);
      let userDoc = await getDoc(doc(db, 'users', user.uid));

      if (!userDoc.exists()) {
        const existingUser = await getUserByEmail(normalizedEmail);
        if (existingUser) {
          await updateDoc(doc(db, 'users', existingUser.id), {
            authMethods: [...new Set([...existingUser.authMethods, 'google'])],
          });
          return { ...result, role: existingUser.role };
        } else {
          await setDoc(doc(db, 'users', user.uid), {
            email: normalizedEmail,
            role: 'user',
            authMethods: ['google'],
          });
          return { ...result, role: 'user' };
        }
      } else {
        const userData = userDoc.data();
        await updateDoc(doc(db, 'users', user.uid), {
          authMethods: [...new Set([...userData.authMethods, 'google'])],
        });
        return { ...result, role: userData.role };
      }
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  }

  async function linkGoogleAccount(email, password) {
    try {
      const normalizedEmail = normalizeEmail(email);
      const credential = EmailAuthProvider.credential(normalizedEmail, password);
      const result = await linkWithCredential(auth.currentUser, credential);
      await updateDoc(doc(db, 'users', result.user.uid), {
        authMethods: [...new Set([...result.user.authMethods, 'password'])],
      });
      return result;
    } catch (error) {
      console.error('Error linking Google account:', error);
      throw error;
    }
  }

  function logout() {
    return signOut(auth);
  }

  async function resetPassword(email) {
    try {
      const normalizedEmail = normalizeEmail(email);
      await sendPasswordResetEmail(auth, normalizedEmail);
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  }

  async function verifyEmail() {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
      } catch (error) {
        console.error('Email verification error:', error);
        throw error;
      }
    } else {
      throw new Error('No user is currently signed in.');
    }
  }

  async function confirmEmailVerification(actionCode) {
    try {
      await applyActionCode(auth, actionCode);
      if (auth.currentUser) {
        await auth.currentUser.reload();
        setCurrentUser((prevUser) => ({ ...prevUser, emailVerified: true }));
      }
    } catch (error) {
      console.error('Error confirming email verification:', error);
      throw error;
    }
  }

  async function updateUserProfile(userData) {
    if (!auth.currentUser) {
      throw new Error('No user is currently signed in.');
    }

    try {
      const { displayName, photoURL, bio } = userData;
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateProfile(auth.currentUser, { displayName, photoURL });
      await updateDoc(userRef, { displayName, photoURL, bio });
      setCurrentUser((prevUser) => ({
        ...prevUser,
        displayName,
        photoURL,
        bio,
      }));
      console.log('User profile updated successfully');
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  }

  async function uploadProfilePicture(file) {
    if (!auth.currentUser) {
      throw new Error('No user is currently signed in.');
    }

    try {
      const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
      const uploadResult = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(uploadResult.ref);
      await updateUserProfile({ photoURL: downloadURL });
      console.log('Profile picture uploaded successfully');
      return downloadURL;
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      throw error;
    }
  }

  useEffect(() => {
    let unsubscribeAuth;
    let unsubscribeRole;

    const setupAuthListener = async () => {
      unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const role = userData.role || 'user';
            console.log('Setting current user with role:', role);
            setCurrentUser({
              ...user,
              role,
              bio: userData.bio || '',
              displayName: user.displayName || userData.displayName || '',
            });
            unsubscribeRole = startRoleListener(user.uid);
          } else {
            console.log('No valid user document found, logging out');
            await signOut(auth);
            setCurrentUser(null);
          }
        } else {
          console.log('No user, setting currentUser to null');
          setCurrentUser(null);
          if (unsubscribeRole) {
            unsubscribeRole();
          }
        }
        setLoading(false);
      });
    };

    setupAuthListener();

    return () => {
      console.log('Cleaning up auth and role listeners');
      if (unsubscribeAuth) unsubscribeAuth();
      if (unsubscribeRole) unsubscribeRole();
    };
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    signInWithGoogle,
    resetPassword,
    verifyEmail,
    confirmEmailVerification,
    getUserRole,
    linkGoogleAccount,
    updateUserProfile,
    uploadProfilePicture,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
