import React, { useState } from 'react';
import { FiLink, FiChevronRight, FiMusic, FiUser, FiExternalLink } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const SubmissionForm = ({ opportunity, onSubmit }) => {
  const [formData, setFormData] = useState({
    trackName: '',
    artistName: '',
    spotifyLink: '',
    isOriginal: false,
  });

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.isOriginal) {
      alert("Please confirm that this is your original music before submitting.");
      return;
    }
    onSubmit(opportunity.id, formData);
    setFormData({ trackName: '', artistName: '', spotifyLink: '', isOriginal: false });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="trackName" className="block text-sm font-medium text-gray-700">Track Name</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiMusic className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="trackName"
            id="trackName"
            value={formData.trackName}
            onChange={handleChange}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md text-gray-900"
            placeholder="Enter track name"
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="artistName" className="block text-sm font-medium text-gray-700">Artist Name</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiUser className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="artistName"
            id="artistName"
            value={formData.artistName}
            onChange={handleChange}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md text-gray-900"
            placeholder="Enter artist name"
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="spotifyLink" className="block text-sm font-medium text-gray-700">Spotify Link</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FiExternalLink className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="url"
            name="spotifyLink"
            id="spotifyLink"
            value={formData.spotifyLink}
            onChange={handleChange}
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md text-gray-900"
            placeholder="Enter Spotify link"
            required
          />
        </div>
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          name="isOriginal"
          id="isOriginal"
          checked={formData.isOriginal}
          onChange={handleChange}
          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
        />
        <label htmlFor="isOriginal" className="ml-2 block text-sm text-gray-900">
          I agree that this is my original music
        </label>
      </div>
      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit Track
      </button>
    </form>
  );
};

const OpportunitiesSection = ({ opportunities, onSelectOpportunity, selectedOpportunityId, onSubmit }) => {
  const selectedOpportunity = opportunities.find(opp => opp.id === selectedOpportunityId);

  return (
    <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
      <section className="bg-white shadow-lg rounded-2xl p-6 transition-all duration-300 ease-in-out flex-1">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Your Opportunities</h2>
        {opportunities.length === 0 ? (
          <p className="text-gray-600">No opportunities available at the moment.</p>
        ) : (
          <ul className="space-y-4">
            {opportunities.map(opportunity => (
              <motion.li 
                key={opportunity.id}
                className={`bg-gray-50 rounded-xl p-4 transition-all duration-300 ease-in-out cursor-pointer ${
                  selectedOpportunityId === opportunity.id ? 'ring-2 ring-indigo-500' : 'hover:shadow-md'
                }`}
                onClick={() => onSelectOpportunity(opportunity.id)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-grow pr-4">
                    {opportunity.imageUrl && (
                      <img src={opportunity.imageUrl} alt={opportunity.title} className="w-full h-40 object-cover rounded-lg mb-4" />
                    )}
                    <h3 className="text-lg font-semibold text-gray-800">{opportunity.title}</h3>
                    <p className="text-gray-600 mt-2">{opportunity.description}</p>
                    {opportunity.endDate && (
                      <p className="text-gray-600 mt-2">End Date: {new Date(opportunity.endDate).toLocaleDateString()}</p>
                    )}
                    {opportunity.link && (
                      <a href={opportunity.link} target="_blank" rel="noopener noreferrer" className="text-indigo-500 hover:text-indigo-600 flex items-center mt-2">
                        <FiLink className="mr-2" /> View Details
                      </a>
                    )}
                  </div>
                  <div className="flex flex-col items-end">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                      opportunity.status === 'open' ? 'bg-green-200 text-green-800' :
                      opportunity.status === 'closed' ? 'bg-red-200 text-red-800' :
                      'bg-yellow-200 text-yellow-800'
                    }`}>
                      {opportunity.status || 'Unknown'}
                    </span>
                    <FiChevronRight className="mt-4 text-gray-400" />
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        )}
      </section>
      <AnimatePresence>
        {selectedOpportunity && (
          <motion.section
            key={selectedOpportunity.id}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-white shadow-lg rounded-2xl p-6 flex-1"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Submit Opportunity for {selectedOpportunity.title}
            </h2>
            <SubmissionForm opportunity={selectedOpportunity} onSubmit={onSubmit} />
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OpportunitiesSection;