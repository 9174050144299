import React, { useRef, useEffect, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { doc, updateDoc, getDoc, setDoc, increment } from 'firebase/firestore';

const UpwardShipGame = ({ userId, opportunityId, scoreClassName }) => {
  const canvasRef = useRef(null);
  const scoreRef = useRef(0);
  const highScoreRef = useRef(0);
  const shipImageRef = useRef(null);
  const [gameOver, setGameOver] = useState(false);
  const [displayScore, setDisplayScore] = useState(0);
  const [displayHighScore, setDisplayHighScore] = useState(0);
  const [totalGameScore, setTotalGameScore] = useState(0);
  const [userName, setUserName] = useState('Anonymous');

  const fetchUserData = useCallback(async () => {
    if (userId) {
      const userRef = doc(db, 'users', userId);
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserName(userData.displayName || 'Anonymous');
          setTotalGameScore(userData.gameScore || 0);
          if (userData.photoURL) {
            shipImageRef.current = new Image();
            shipImageRef.current.src = userData.photoURL;
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  }, [userId]);

  const updateHighScoreInDB = useCallback(async (newHighScore) => {
    if (userId && opportunityId) {
      const userOpportunityRef = doc(db, 'users', userId, 'opportunities', opportunityId);
      try {
        const docSnap = await getDoc(userOpportunityRef);
        if (docSnap.exists()) {
          const currentHighScore = docSnap.data().highScore || 0;
          if (newHighScore > currentHighScore) {
            await updateDoc(userOpportunityRef, { highScore: newHighScore });
          }
        } else {
          await setDoc(userOpportunityRef, { highScore: newHighScore });
        }
      } catch (error) {
        console.error("Error updating high score:", error);
      }
    }
  }, [userId, opportunityId]);

  const updateScore = useCallback(() => {
    setDisplayScore(scoreRef.current);
    setDisplayHighScore(highScoreRef.current);
    updateHighScoreInDB(highScoreRef.current);
  }, [updateHighScoreInDB]);

  const updateTotalGameScore = useCallback(async () => {
    if (userId) {
      const userRef = doc(db, 'users', userId);
      try {
        await updateDoc(userRef, { gameScore: increment(1) });
        setTotalGameScore(prevScore => prevScore + 1);
      } catch (error) {
        console.error("Error updating total game score:", error);
      }
    }
  }, [userId]);

  useEffect(() => {
    fetchUserData();

    const fetchHighScore = async () => {
      if (userId && opportunityId) {
        const userOpportunityRef = doc(db, 'users', userId, 'opportunities', opportunityId);
        try {
          const docSnap = await getDoc(userOpportunityRef);
          if (docSnap.exists()) {
            const dbHighScore = docSnap.data().highScore || 0;
            highScoreRef.current = Math.max(highScoreRef.current, dbHighScore);
            setDisplayHighScore(highScoreRef.current);
          }
        } catch (error) {
          console.error("Error fetching high score:", error);
        }
      }
    };

    fetchHighScore();

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const CANVAS_WIDTH = canvas.width;
    const CANVAS_HEIGHT = canvas.height;
    const SHIP_WIDTH = 40;
    const SHIP_HEIGHT = 40;
    const OBSTACLE_HEIGHT = 20;
    const NUM_OBSTACLES = 4;

    let animationFrameId;
    let gameSpeed = 2;

    const artistStruggles = [
      'BILLS', 'GATEKEEPERS', 'DOUBT', 'FEAR', 'SCAMS', 
      'REJECTION', 'BURNOUT', 'COMPETITION', 'PIRACY'
    ];

    const ship = {
      x: CANVAS_WIDTH / 2 - SHIP_WIDTH / 2,
      y: CANVAS_HEIGHT - SHIP_HEIGHT - 10,
      width: SHIP_WIDTH,
      height: SHIP_HEIGHT,
      speed: 5
    };

    const obstacles = Array(NUM_OBSTACLES).fill().map((_, index) => ({
      x: Math.random() * (CANVAS_WIDTH - 100),
      y: -OBSTACLE_HEIGHT - index * (CANVAS_HEIGHT / NUM_OBSTACLES),
      width: Math.random() * 100 + 50,
      height: OBSTACLE_HEIGHT,
      text: artistStruggles[Math.floor(Math.random() * artistStruggles.length)],
      passed: false
    }));

    const keys = {
      ArrowLeft: false,
      ArrowRight: false
    };

    const drawShip = () => {
      if (shipImageRef.current && shipImageRef.current.complete) {
        ctx.drawImage(shipImageRef.current, ship.x, ship.y, ship.width, ship.height);
      } else {
        ctx.fillStyle = '#4A90E2';
        ctx.fillRect(ship.x, ship.y, ship.width, ship.height);
      }
      ctx.fillStyle = 'white';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(userName, ship.x + ship.width / 2, ship.y - 5);
    };

    const drawObstacle = (obstacle) => {
      ctx.fillStyle = '#E74C3C';
      ctx.fillRect(obstacle.x, obstacle.y, obstacle.width, obstacle.height);
      ctx.fillStyle = 'white';
      ctx.font = '10px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(obstacle.text, obstacle.x + obstacle.width / 2, obstacle.y + obstacle.height / 2);
    };

    const drawBackground = () => {
      ctx.fillStyle = '#87CEEB';
      ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    };

    const moveShip = () => {
      if (keys.ArrowLeft && ship.x > 0) ship.x -= ship.speed;
      if (keys.ArrowRight && ship.x < CANVAS_WIDTH - ship.width) ship.x += ship.speed;
    };

    const checkCollision = (ship, obstacle) => {
      return (
        ship.x < obstacle.x + obstacle.width &&
        ship.x + ship.width > obstacle.x &&
        ship.y < obstacle.y + obstacle.height &&
        ship.y + ship.height > obstacle.y
      );
    };

    const updateGameState = () => {
      moveShip();

      obstacles.forEach((obstacle) => {
        obstacle.y += gameSpeed;

        if (obstacle.y > CANVAS_HEIGHT) {
          obstacle.y = -OBSTACLE_HEIGHT;
          obstacle.x = Math.random() * (CANVAS_WIDTH - 100);
          obstacle.width = Math.random() * 100 + 50;
          obstacle.text = artistStruggles[Math.floor(Math.random() * artistStruggles.length)];
          obstacle.passed = false;
        }

        if (!obstacle.passed && obstacle.y + obstacle.height > ship.y) {
          obstacle.passed = true;
          scoreRef.current += 1;
          updateTotalGameScore();
          if (scoreRef.current > highScoreRef.current) {
            highScoreRef.current = scoreRef.current;
            updateScore();
          }
        }

        if (checkCollision(ship, obstacle)) {
          setGameOver(true);
          updateScore();
        }
      });
    };

    const gameLoop = () => {
      if (!gameOver) {
        updateGameState();
        ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        drawBackground();
        obstacles.forEach(drawObstacle);
        drawShip();

        ctx.fillStyle = 'black';
        ctx.font = '20px Arial';
        ctx.textAlign = 'left';
        ctx.fillText(`Score: ${scoreRef.current}`, 10, 30);
        ctx.fillText(`High Score: ${highScoreRef.current}`, 10, 60);
        ctx.fillText(`Total Game Score: ${totalGameScore}`, 10, 90);

        animationFrameId = requestAnimationFrame(gameLoop);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key in keys) {
        keys[e.key] = true;
      }
    };

    const handleKeyUp = (e) => {
      if (e.key in keys) {
        keys[e.key] = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    gameLoop();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [gameOver, userId, opportunityId, updateScore, updateTotalGameScore, fetchUserData, userName]);

  const handleRestart = () => {
    scoreRef.current = 0;
    setGameOver(false);
    setDisplayScore(0);
    setDisplayHighScore(highScoreRef.current);
    fetchUserData();
  };

  return (
    <div className="flex flex-col items-center">
      <canvas ref={canvasRef} width={400} height={400} className="border border-gray-300" />
      <p className="mt-2 text-sm text-gray-600">Use Left and Right arrow keys to move the ship</p>
      {gameOver && (
        <div className="mt-4">
          <p className={`text-xl font-bold ${scoreClassName}`}>Game Over! Score: {displayScore}</p>
          <p className={`text-lg ${scoreClassName}`}>High Score: {displayHighScore}</p>
          <p className={`text-lg ${scoreClassName}`}>Total Game Score: {totalGameScore}</p>
          <button
            onClick={handleRestart}
            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Restart
          </button>
        </div>
      )}
    </div>
  );
};

export default UpwardShipGame;