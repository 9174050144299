import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FcGoogle } from 'react-icons/fc';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { login, signInWithGoogle, resetPassword } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    if (isForgotPassword) {
      await handleForgotPassword();
    } else {
      try {
        setError('');
        setMessage('');
        setLoading(true);
        const userCredential = await login(email, password);
        console.log('Login successful, user role:', userCredential.role);
        if (!userCredential.user.emailVerified) {
          setError('Please verify your email before logging in.');
          setLoading(false);
          return;
        }
        navigate(userCredential.role === 'admin' ? '/admin-dashboard' : '/dashboard');
      } catch (error) {
        console.error('Login error:', error);
        if (error.message === 'INVALID_CREDENTIALS') {
          setError('Invalid email or password. Please try again.');
        } else if (error.message === 'GOOGLE_ACCOUNT') {
          setError('This email is associated with a Google account. Please use Google Sign-In.');
        } else {
          setError('Failed to log in. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleGoogleLogin() {
    try {
      setError('');
      setMessage('');
      setLoading(true);
      const userCredential = await signInWithGoogle();
      console.log('Google login successful, user role:', userCredential.role);
      navigate(userCredential.role === 'admin' ? '/admin-dashboard' : '/dashboard');
    } catch (error) {
      console.error('Google login error:', error);
      setError('Failed to log in with Google. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  async function handleForgotPassword() {
    try {
      setError('');
      setMessage('');
      if (!email) {
        setError('Please enter your email address.');
        return;
      }
      await resetPassword(email);
      setMessage('Password reset email sent. Please check your inbox.');
      setIsForgotPassword(false);
    } catch (error) {
      console.error('Password reset error:', error);
      setError('Failed to reset password. Please try again later.');
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-800 to-blue-400 py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <svg className="absolute inset-0 w-full h-full opacity-50" xmlns="http://www.w3.org/2000/svg">
        <filter id="noise">
          <feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch"/>
          <feColorMatrix type="saturate" values="0"/>
        </filter>
        <rect width="100%" height="100%" filter="url(#noise)"/>
      </svg>
      <div className="max-w-md w-full space-y-8 bg-white bg-opacity-90 p-10 rounded-xl shadow-2xl relative z-10">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isForgotPassword ? 'Reset Your Password' : 'Log In to Your Account'}
          </h2>
        </div>
        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
        {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">{message}</div>}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email address</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {!isForgotPassword && (
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            )}
          </div>

          {!isForgotPassword && (
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => setIsForgotPassword(true)}
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </button>
              </div>
            </div>
          )}

          <div>
            <button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              {loading ? 'Processing...' : (isForgotPassword ? 'Reset My Password' : 'Log In')}
            </button>
          </div>
        </form>
        
        {!isForgotPassword && (
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleGoogleLogin}
                disabled={loading}
                className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
              >
                <FcGoogle className="w-5 h-5 mr-2" />
                Log in with Google
              </button>
            </div>
          </div>
        )}

        {isForgotPassword && (
          <div className="mt-4 text-sm text-center">
            <button
              type="button"
              onClick={() => setIsForgotPassword(false)}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Back to Login
            </button>
          </div>
        )}

        {/* New section for Signup link */}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{' '}
            <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
              Sign up here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}