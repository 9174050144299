import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import SharedOpportunityView from './SharedOpportunityView';

const ShortLinkRedirect = () => {
  const { shortId } = useParams();

  if (!shortId) {
    return <Navigate to="/" replace />;
  }

  return <SharedOpportunityView />;
};

export default ShortLinkRedirect;