import React, { useState, useEffect } from 'react';
import { fetchAllSubmissions } from '../../utils/firebaseUtils';
import { FiExternalLink } from 'react-icons/fi';

const SubmissionsPanel = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionsPerPage] = useState(10);

  useEffect(() => {
    const loadSubmissions = async () => {
      try {
        const fetchedSubmissions = await fetchAllSubmissions();
        setSubmissions(fetchedSubmissions);
      } catch (err) {
        setError('Failed to load submissions. Please try again.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadSubmissions();
  }, []);

  if (loading) return <div className="text-gray-800">Loading submissions...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  // Get current submissions
  const indexOfLastSubmission = currentPage * submissionsPerPage;
  const indexOfFirstSubmission = indexOfLastSubmission - submissionsPerPage;
  const currentSubmissions = submissions.slice(indexOfFirstSubmission, indexOfLastSubmission);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">User Submissions</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Track Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Artist Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Spotify Link</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Submission Date</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentSubmissions.map((submission) => (
              <tr key={submission.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{submission.userId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{submission.trackName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{submission.artistName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <a href={submission.spotifyLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-900">
                    <FiExternalLink className="inline mr-1" />
                    Listen
                  </a>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                  {submission.createdAt ? new Date(submission.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center">
        {Array.from({ length: Math.ceil(submissions.length / submissionsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`mx-1 px-3 py-1 border rounded ${
              currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 hover:bg-blue-100'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubmissionsPanel;