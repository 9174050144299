import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../../firebase';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { FiShare2, FiPlay, FiPause, FiMusic, FiRefreshCw } from 'react-icons/fi';
import { Helmet } from 'react-helmet';
import UpwardShipGame from './UpwardShipGame';

const AudioPlayer = ({ audioUrl, albumArtUrl, trackName, artistName }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(audioUrl));

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.pause();
    };
  }, [audioUrl]);

  const updateProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (e) => {
    const seekTime = (e.nativeEvent.offsetX / e.target.offsetWidth) * duration;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="bg-blue-600 rounded-lg shadow-lg p-6 mb-8 text-white">
      <div className="flex items-center space-x-4 mb-4">
        <img src={albumArtUrl} alt="Album Art" className="w-20 h-20 object-cover rounded-full shadow-lg" />
        <div>
          <h3 className="text-xl font-bold mb-1 font-typewriter">{trackName}</h3>
          <p className="text-sm opacity-80 font-typewriter">{artistName}</p>
        </div>
      </div>
      <div className="mb-4">
        <div className="bg-blue-400 rounded-full h-2 cursor-pointer" onClick={handleSeek}>
          <div 
            className="bg-white h-2 rounded-full" 
            style={{ width: `${(currentTime / duration) * 100}%` }}
          ></div>
        </div>
        <div className="flex justify-between text-xs mt-1 font-typewriter">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      <button 
        onClick={togglePlay}
        className="w-full p-2 bg-white text-blue-600 rounded-full hover:bg-opacity-90 transition duration-300 flex items-center justify-center text-lg font-semibold shadow-lg font-typewriter"
      >
        {isPlaying ? <FiPause className="mr-2" /> : <FiPlay className="mr-2" />}
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    </div>
  );
};

const SharedOpportunityView = () => {
  const { shortId } = useParams();
  const [submission, setSubmission] = useState(null);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const fetchData = useCallback(async () => {
    console.log('Fetching data for shortId:', shortId);
    setLoading(true);
    setError(null);
  
    try {
      if (!shortId) {
        throw new Error('Missing shortId');
      }
  
      // Query for the short link document
      const shortLinksRef = collection(db, 'shortLinks');
      const q = query(shortLinksRef, where("shortId", "==", shortId));
      console.log('Querying shortLinks collection...');
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        throw new Error('Invalid short link');
      }
  
      const shortLinkDoc = querySnapshot.docs[0];
      const shortLinkData = shortLinkDoc.data();
      console.log('Short link data:', shortLinkData);
  
      // Extract opportunityId from fullUrl
      const fullUrl = new URL(shortLinkData.fullUrl);
      const pathParts = fullUrl.pathname.split('/');
      const opportunityId = pathParts[pathParts.length - 1];
  
      console.log('Extracted opportunityId:', opportunityId);
  
      if (!opportunityId) {
        throw new Error('Invalid opportunityId extracted from shortLink');
      }
  
      // Query for the submission
      const submissionsRef = collection(db, 'submissions');
      const submissionQuery = query(submissionsRef, where("opportunityId", "==", opportunityId));
      console.log('Querying submissions collection...');
      
      const submissionSnapshot = await getDocs(submissionQuery);
      console.log('Submission query result:', submissionSnapshot.size, 'documents');
  
      if (!submissionSnapshot.empty) {
        const submissionDoc = submissionSnapshot.docs[0];
        const submissionData = submissionDoc.data();
        console.log('Submission data:', submissionData);

        // Fetch user data
        const userRef = doc(db, 'users', submissionData.userId);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserName(userData.displayName || 'Unknown User');
        } else {
          console.log('No user found for userId:', submissionData.userId);
          setUserName('Unknown User');
        }

        setSubmission({
          ...submissionData,
          id: submissionDoc.id,
          opportunityId
        });
      } else {
        console.log('No matching submission found for opportunityId:', opportunityId);
        setSubmission(null);
      }
    } catch (err) {
      console.error('Error details:', err);
      setError('Error fetching data: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [shortId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowSharePopup(true);
    setTimeout(() => setShowSharePopup(false), 3000);
  };

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 to-blue-600">
      <div className="text-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-white mb-4"></div>
        <p className="text-white font-typewriter">Loading challenge...</p>
      </div>
    </div>
  );

  if (error) return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-400 to-blue-600 text-white">
      <div className="bg-white bg-opacity-20 rounded-lg p-8 backdrop-filter backdrop-blur-lg">
        <p className="text-2xl font-bold mb-4 font-typewriter">Oops! Something went wrong.</p>
        <p className="font-typewriter mb-4">{error}</p>
        <button 
          onClick={fetchData}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FiRefreshCw className="mr-2" /> Try Again
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-400 to-blue-600 py-12 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>{submission ? `🎵 Challenge: Beat ${userName}'s Score!` : 'Music Challenge'}</title>
        <link href="https://fonts.googleapis.com/css2?family=Special+Elite&display=swap" rel="stylesheet" />
        <style>{`
          .font-typewriter {
            font-family: 'Special Elite', cursive;
          }
        `}</style>
      </Helmet>
      <div className="max-w-md mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-2xl rounded-3xl overflow-hidden"
        >
          <div className="p-6">
            <h1 className="text-3xl font-extrabold text-center mb-6 text-blue-600 font-typewriter">
              {submission ? `${userName}'s Challenge` : 'No Challenge Found'}
            </h1>
            
            {submission ? (
              <>
                <AudioPlayer 
                  audioUrl={submission.audioUrl}
                  albumArtUrl={submission.albumArtUrl}
                  trackName={submission.trackName}
                  artistName={userName}
                />
                <div className="mt-6 mb-4 text-center">
                  <p className="text-xl font-bold text-blue-600 mb-2 font-typewriter">
                    Can you beat the high score?
                  </p>
                  <p className="text-sm text-blue-500 font-typewriter">
                    Play the game while listening to the track!
                  </p>
                </div>
                
                <div className="bg-blue-50 rounded-2xl p-4 mb-6">
                  <UpwardShipGame 
                    opportunityId={submission.opportunityId}
                    scoreClassName="text-blue-800 font-bold"
                  />
                </div>
                
                <button
                  onClick={handleShare}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition duration-300 shadow-lg font-typewriter"
                >
                  <FiShare2 className="mr-2" /> Challenge Friends
                </button>
              </>
            ) : (
              <div className="text-center py-8">
                <FiMusic className="mx-auto text-5xl text-blue-400 mb-4" />
                <p className="text-xl font-bold text-blue-600 mb-2 font-typewriter">No music challenge found</p>
                <p className="text-sm text-blue-500 font-typewriter">This challenge might not exist or has been removed.</p>
              </div>
            )}
          </div>
        </motion.div>
        <div className="mt-4 text-center">
          <a 
            href="https://www.submittt.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-blue-200 transition duration-300 font-typewriter text-sm"
          >
            Give Experiences to your listeners
          </a>
        </div>
      </div>
      <AnimatePresence>
        {showSharePopup && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-blue-600 px-4 py-2 rounded-full shadow-lg font-typewriter"
          >
            Your link is ready to share!
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SharedOpportunityView;