// src/utils/firebaseUtils.js

import { db, auth } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp 
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';

export const fetchUserOpportunities = async (userId) => {
  try {
    const q = query(collection(db, 'opportunities'), where('assignedUsers', 'array-contains', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching user opportunities:', error);
    throw error;
  }
};

export const fetchUserSubmissions = async (userId) => {
  try {
    const q = query(collection(db, 'submissions'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching user submissions:', error);
    throw error;
  }
};

export const fetchOpportunities = async () => {
  try {
    const opportunitiesRef = collection(db, 'opportunities');
    const snapshot = await getDocs(opportunitiesRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching opportunities:', error);
    throw error;
  }
};

export const submitTrack = async (userId, opportunityId, formData) => {
  try {
    const submissionsRef = collection(db, 'submissions');
    const newSubmission = {
      userId,
      opportunityId,
      ...formData,
      createdAt: serverTimestamp()
    };
    const docRef = await addDoc(submissionsRef, newSubmission);
    return { id: docRef.id, ...newSubmission };
  } catch (error) {
    console.error('Error submitting track:', error);
    throw error;
  }
};

export const fetchAllSubmissions = async () => {
  try {
    const submissionsRef = collection(db, 'submissions');
    const snapshot = await getDocs(submissionsRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching all submissions:', error);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const addUser = async (userData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
    const newUser = {
      uid: userCredential.user.uid,
      email: userData.email,
      role: userData.role,
      createdAt: serverTimestamp()
    };
    const docRef = await addDoc(collection(db, 'users'), newUser);
    return { id: docRef.id, ...newUser };
  } catch (error) {
    console.error('Error adding new user:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      email: userData.email,
      role: userData.role
    });
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

// New functions for updating and deleting submissions

export const updateSubmission = async (submissionId, formData) => {
  try {
    const submissionRef = doc(db, 'submissions', submissionId);
    await updateDoc(submissionRef, {
      ...formData,
      updatedAt: serverTimestamp()
    });
    return { id: submissionId, ...formData };
  } catch (error) {
    console.error('Error updating submission:', error);
    throw error;
  }
};

export const deleteSubmission = async (submissionId) => {
  try {
    await deleteDoc(doc(db, 'submissions', submissionId));
  } catch (error) {
    console.error('Error deleting submission:', error);
    throw error;
  }
};