import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { fetchUsers } from '../../utils/firebaseUtils';
import { 
  FiUsers, 
  FiBriefcase, 
  FiFileText, 
  FiLogOut, 
  FiEye
} from 'react-icons/fi';
import UsersPanel from './UsersPanel';
import OpportunitiesPanel from './OpportunitiesPanel';
import SubmissionsPanel from './SubmissionsPanel';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('users');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadUsers = async () => {
      try {
        setLoading(true);
        const fetchedUsers = await fetchUsers();
        setUsers(fetchedUsers);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load users. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
      setError('Failed to log out. Please try again.');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'users':
        return <UsersPanel users={users} setUsers={setUsers} />;
      case 'opportunities':
        return <OpportunitiesPanel users={users} />;
      case 'submissions':
        return <SubmissionsPanel />;
      default:
        return <div className="text-gray-800">Invalid tab</div>;
    }
  };

  if (loading) return <div className="flex justify-center items-center h-screen text-gray-800">Loading...</div>;
  if (error) return <div className="text-red-500 text-center mt-8">{error}</div>;

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-800">Admin Dashboard</h2>
        </div>
        <nav className="mt-6">
          {['users', 'opportunities', 'submissions'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`flex items-center w-full py-3 px-6 text-left ${
                activeTab === tab
                  ? 'bg-gray-200 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              } transition duration-150 ease-in-out`}
            >
              {tab === 'users' && <FiUsers className="mr-3 h-5 w-5" />}
              {tab === 'opportunities' && <FiBriefcase className="mr-3 h-5 w-5" />}
              {tab === 'submissions' && <FiFileText className="mr-3 h-5 w-5" />}
              <span className="capitalize">{tab}</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-2xl font-bold text-gray-800">Admin Dashboard</h1>
              </div>
              <div className="flex items-center">
                <button 
                  onClick={() => navigate('/user-dashboard', { state: { isAdminView: true } })}
                  className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                >
                  <FiEye className="mr-1" /> 
                  <span>View as User</span>
                </button>
                <button 
                  onClick={handleLogout} 
                  className="ml-4 text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                >
                  <FiLogOut className="mr-1" /> 
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main content */}
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md rounded-lg p-6">
            {renderTabContent()}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;