import React, { useState, useRef } from 'react';
import './ProducersFinder.css';

const ProducersFinder = () => {
  const [loading, setLoading] = useState(false);
  const [producers, setProducers] = useState([]);
  const inputRef = useRef(null);

  const showNotification = (message) => {
    const notification = document.createElement('div');
    notification.className = 'notification';
    notification.innerText = message;
    document.body.appendChild(notification);
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 3000);
  };

  const fetchEmails = async (channelIds) => {
    const emails = await Promise.all(
      channelIds.map(async (id) => {
        const response = await fetch(`https://youtube-channel-email-scraper.p.rapidapi.com/email?channel_id=${id}`, {
          method: 'GET',
          headers: {
            'x-rapidapi-host': 'youtube-channel-email-scraper.p.rapidapi.com',
            'x-rapidapi-key': '9f9abe3d48mshbe37fee452bc816p15ff6djsna5b9b8ceafa2',
          },
        });
        const data = await response.json();
        return data.email ? { id, email: data.email } : null;
      })
    );
    return emails.filter(Boolean);
  };

  const fetchProducers = async () => {
    if (inputRef.current.value === '') {
      return;
    }
    setLoading(true);
    const userInput = inputRef.current.value;
    try {
      const formData = new FormData();
      formData.append('query', userInput);

      const response = await fetch('https://youtube-scraper-2023.p.rapidapi.com/search_video', {
        method: 'POST',
        headers: {
          'x-rapidapi-host': 'youtube-scraper-2023.p.rapidapi.com',
          'x-rapidapi-key': '9f9abe3d48mshbe37fee452bc816p15ff6djsna5b9b8ceafa2',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      if (data && data.search_results) {
        const channels = data.search_results.map((result) => ({
          handle: result.channel.handle,
          id: result.channel.id,
          title: result.title,
          thumbnail: result.thumbnails[0].url,
          viewCountText: result.viewCountText,
          url: result.url,
        }));

        const uniqueChannels = Array.from(new Set(channels.map(channel => channel.id)))
          .map(id => channels.find(channel => channel.id === id));

        const emails = await fetchEmails(uniqueChannels.map((channel) => channel.id));
        const producersWithEmails = uniqueChannels.map((channel) => ({
          ...channel,
          email: emails.find((email) => email.id === channel.id)?.email || 'No email found',
        }));

        setProducers(producersWithEmails);
      } else {
        console.error('Unexpected API response structure:', data);
        setProducers([]);
      }
    } catch (error) {
      console.error('Error fetching producers:', error);
      setProducers([]);
    } finally {
      setLoading(false);
    }
  };

  const copyEmail = (email) => {
    navigator.clipboard.writeText(email);
    showNotification('Your Email has been copied 🫡');
  };

  const copyEmailTemplate = (producer) => {
    const viewCount = parseInt(producer.viewCountText.replace(/[^\d]/g, ''));
    let emailTemplate = `Hey, is ${producer.handle} right?\n\nI really dig what you're doing with ${producer.title}.\n\nMind if I send you a couple of samples of my own compositions?\n\nIf you're cool with that, just reply to this email.\n\nBest,\n[Your Name]`;

    if (producer.viewCountText.toLowerCase().includes('no views')) {
      emailTemplate = `Hey, is ${producer.handle}?\n\nI really dig what you're doing with ${producer.title}.\n\nMind if I send you _________ my own _____\n\nIf you're cool with that, just reply to this email.\n\nMaybe this video is getting started but I think you have potential.\n\nBest,\n[Your Name]`;
    } else if (viewCount < 1000) {
      emailTemplate = `Hey, is ${producer.handle} right?\n\nI really dig what you're doing with ${producer.title}.\n\nMind if I send you _________ my own __________\n\nIf you're cool with that, just reply to this email.\n\nKeep pushing I see you got ${producer.viewCountText} already!!\n\nBest,\n[Your Name]`;
    } else {
      emailTemplate = `Hey, is ${producer.handle} right?\n\nI really dig what you're doing with ${producer.title}.\n\nMind if I send you _________of my own _________\n\nIf you're cool with that, just reply to this email.\n\nOh, and congrats on those ${producer.viewCountText}! That's really cool though.\n\nBest,\n[Your Name]`;
    }
    
    navigator.clipboard.writeText(emailTemplate);
    showNotification('This template has been copied 🫡');
  };

  return (
    <div className="producers-finder">
      <div className="header">Collab? <span>🎚</span></div>
      <div className="search-box">
        <input type="text" ref={inputRef} className="search-input" placeholder="Put a few Keywords your ideal audience would use" />
        <div className="generate-btn" onClick={fetchProducers}>Find Influencers</div>
      </div>
      <div className="producers-list">
        {loading ? <div className="loading-text">Loading...</div> : (
          producers.map((producer) => (
            <div key={producer.id} className="producer-card">
              <div className="profile-picture-container">
                <img src={producer.thumbnail} alt="Profile Picture" className="profile-picture" />
              </div>
              <div className="producer-info">
                <p><strong>Influencer Name:</strong> {producer.handle}</p>
                <p><strong>Views:</strong> {producer.viewCountText}</p>
                <p>
                  <strong>Email:</strong> 
                  <span className="email" onClick={() => copyEmail(producer.email)} title="Click to copy">{producer.email}</span>
                </p>
                <p><strong>Video Tittle:</strong> {producer.title}</p>
                <p><strong>Video link:</strong> <a href={producer.url} target="_blank" rel="noopener noreferrer">Watch on YouTube</a></p>
                <button onClick={() => copyEmailTemplate(producer)}>Copy email template</button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ProducersFinder;
