import React, { useState } from 'react';
import { FiEdit2, FiTrash2, FiUserPlus } from 'react-icons/fi';
import { addUser, updateUser, deleteUser } from '../../utils/firebaseUtils';

const UsersPanel = ({ users, setUsers }) => {
  const [newUser, setNewUser] = useState({ email: '', password: '', role: 'user' });
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = users.filter(user => 
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const addedUser = await addUser(newUser);
      setUsers([...users, addedUser]);
      setNewUser({ email: '', password: '', role: 'user' });
    } catch (error) {
      console.error('Error adding user:', error);
      alert('Failed to add user. Please try again.');
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      await updateUser(editingUser.id, editingUser);
      setUsers(users.map(user => user.id === editingUser.id ? editingUser : user));
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user. Please try again.');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        setUsers(users.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user. Please try again.');
      }
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Manage Users</h2>
      
      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search users..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border rounded text-gray-800 placeholder-gray-500"
      />

      {/* User List */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredUsers.map(user => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap text-gray-800">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-800">{user.role}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button 
                    onClick={() => setEditingUser(user)} 
                    className="text-blue-600 hover:text-blue-900 mr-2"
                    aria-label="Edit user"
                  >
                    <FiEdit2 />
                  </button>
                  <button 
                    onClick={() => handleDeleteUser(user.id)} 
                    className="text-red-600 hover:text-red-900"
                    aria-label="Delete user"
                  >
                    <FiTrash2 />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add/Edit User Form */}
      <form onSubmit={editingUser ? handleUpdateUser : handleAddUser} className="mt-8">
        <h3 className="text-lg font-semibold mb-4 text-gray-800">
          {editingUser ? 'Edit User' : 'Add New User'}
        </h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <input
            type="email"
            placeholder="Email"
            value={editingUser ? editingUser.email : newUser.email}
            onChange={(e) => editingUser 
              ? setEditingUser({...editingUser, email: e.target.value})
              : setNewUser({...newUser, email: e.target.value})
            }
            className="w-full p-2 border rounded text-gray-800 placeholder-gray-500"
            required
          />
          {!editingUser && (
            <input
              type="password"
              placeholder="Password"
              value={newUser.password}
              onChange={(e) => setNewUser({...newUser, password: e.target.value})}
              className="w-full p-2 border rounded text-gray-800 placeholder-gray-500"
              required
            />
          )}
          <select
            value={editingUser ? editingUser.role : newUser.role}
            onChange={(e) => editingUser
              ? setEditingUser({...editingUser, role: e.target.value})
              : setNewUser({...newUser, role: e.target.value})
            }
            className="w-full p-2 border rounded text-gray-800 bg-white"
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button 
          type="submit" 
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {editingUser ? 'Update User' : 'Add User'}
        </button>
        {editingUser && (
          <button 
            type="button" 
            onClick={() => setEditingUser(null)} 
            className="mt-4 ml-2 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Cancel
          </button>
        )}
      </form>
    </div>
  );
};

export default UsersPanel;