import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { fetchOpportunities, fetchUserSubmissions, submitTrack, updateSubmission, deleteSubmission } from '../../utils/firebaseUtils';
import { FiLink, FiEdit, FiTrash2, FiMenu } from 'react-icons/fi';
import Sidebar from './Sidebar';
import OpportunitiesSection from './OpportunitiesSection';
import LoadingView from './LoadingView';
import ErrorView from './ErrorView';

const UserDashboard = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
  const [editingSubmission, setEditingSubmission] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (currentUser) {
      try {
        const [fetchedOpportunities, fetchedSubmissions] = await Promise.all([
          fetchOpportunities(),
          fetchUserSubmissions(currentUser.uid)
        ]);
        setOpportunities(fetchedOpportunities);
        setSubmissions(fetchedSubmissions);
        
        if (fetchedOpportunities.length > 0 && !selectedOpportunityId) {
          setSelectedOpportunityId(fetchedOpportunities[0].id);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load dashboard data. Please try again.');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [currentUser, selectedOpportunityId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error('Error logging out:', err);
      setError('Failed to log out. Please try again.');
    }
  };

  const handleOpportunitySelect = (opportunityId) => {
    setSelectedOpportunityId(opportunityId);
    setIsSidebarOpen(false);
  };

  const handleSubmit = async (opportunityId, formData) => {
    try {
      const opportunitySubmissions = submissions.filter(sub => sub.opportunityId === opportunityId);
      if (opportunitySubmissions.length >= 2) {
        setError('You have reached the maximum of 2 submissions for this opportunity.');
        return;
      }

      await submitTrack(currentUser.uid, opportunityId, formData);
      await fetchData(); // Refresh data after submission
    } catch (err) {
      console.error('Error submitting track:', err);
      setError('Failed to submit track. Please try again.');
    }
  };

  const handleEdit = (submission) => {
    setEditingSubmission(submission);
  };

  const handleUpdate = async (submissionId, formData) => {
    try {
      await updateSubmission(submissionId, formData);
      setEditingSubmission(null);
      await fetchData(); // Refresh data after update
    } catch (err) {
      console.error('Error updating submission:', err);
      setError('Failed to update submission. Please try again.');
    }
  };

  const handleDelete = async (submissionId) => {
    if (window.confirm('Are you sure you want to delete this submission?')) {
      try {
        await deleteSubmission(submissionId);
        await fetchData(); // Refresh data after deletion
      } catch (err) {
        console.error('Error deleting submission:', err);
        setError('Failed to delete submission. Please try again.');
      }
    }
  };

  if (loading) return <LoadingView />;
  if (!currentUser) return <ErrorView message="Please log in to view your dashboard." />;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row flex-1">
        <div className="md:hidden bg-white p-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">Your Dashboard</h1>
          <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-gray-600 hover:text-gray-800">
            <FiMenu size={24} />
          </button>
        </div>
        <Sidebar 
          currentUser={currentUser} 
          onLogout={handleLogout}
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
        <main className="flex-1 p-4 md:p-8 overflow-y-auto">
          <h1 className="hidden md:block text-3xl md:text-4xl font-bold text-gray-800 mb-6 md:mb-12">Your Dashboard</h1>
          <OpportunitiesSection 
            opportunities={opportunities} 
            onSelectOpportunity={handleOpportunitySelect}
            selectedOpportunityId={selectedOpportunityId}
            onSubmit={handleSubmit}
          />
          <section className="mt-8 md:mt-12 bg-white shadow-lg rounded-2xl p-4 md:p-6">
            <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4 md:mb-6">Your Submissions</h2>
            {submissions.length === 0 ? (
              <p className="text-gray-600">You haven't made any submissions yet.</p>
            ) : (
              <ul className="space-y-4">
                {submissions.map(submission => (
                  <li key={submission.id} className="bg-gray-50 rounded-xl p-4">
                    {editingSubmission && editingSubmission.id === submission.id ? (
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdate(submission.id, {
                          trackName: e.target.trackName.value,
                          artistName: e.target.artistName.value,
                          spotifyLink: e.target.spotifyLink.value
                        });
                      }} className="space-y-2">
                        <input name="trackName" defaultValue={submission.trackName} className="mb-2 p-2 w-full rounded border" />
                        <input name="artistName" defaultValue={submission.artistName} className="mb-2 p-2 w-full rounded border" />
                        <input name="spotifyLink" defaultValue={submission.spotifyLink} className="mb-2 p-2 w-full rounded border" />
                        <div className="flex flex-wrap gap-2">
                          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                          <button onClick={() => setEditingSubmission(null)} className="bg-gray-300 px-4 py-2 rounded">Cancel</button>
                        </div>
                      </form>
                    ) : (
                      <>
                        <h3 className="text-lg font-semibold text-gray-800">{submission.trackName}</h3>
                        <p className="text-gray-600">Artist: {submission.artistName}</p>
                        <a 
                          href={submission.spotifyLink} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-blue-500 hover:text-blue-600 flex items-center mt-2"
                        >
                          <FiLink className="mr-2" /> Listen on Spotify
                        </a>
                        <p className="text-gray-500 mt-2 text-sm">
                          Submitted for: {opportunities.find(opp => opp.id === submission.opportunityId)?.title || 'Unknown Opportunity'}
                        </p>
                        <div className="mt-4 flex flex-wrap gap-2">
                          <button onClick={() => handleEdit(submission)} className="flex items-center text-blue-500 hover:text-blue-600">
                            <FiEdit className="mr-1" /> Edit
                          </button>
                          <button onClick={() => handleDelete(submission.id)} className="flex items-center text-red-500 hover:text-red-600">
                            <FiTrash2 className="mr-1" /> Delete
                          </button>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </section>
        </main>
      </div>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
    </div>
  );
};

export default UserDashboard;