import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiUser, FiBriefcase, FiFileText, FiChevronRight, FiLogOut, FiSettings, FiMenu, FiX } from 'react-icons/fi';

const Sidebar = ({ currentUser, onLogout, onProfileClick, isOpen, onClose }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    if (onClose) onClose();
  };

  const sidebarContent = (
    <>
      <div className="p-4 md:p-6 flex-grow overflow-y-auto">
        <div 
          className="flex items-center space-x-3 mb-6 md:mb-8 cursor-pointer hover:bg-gray-100 p-2 rounded transition duration-150"
          onClick={() => {
            onProfileClick();
            closeMobileMenu();
          }}
        >
          <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
            {currentUser.photoURL ? (
              <img src={currentUser.photoURL} alt={currentUser.displayName} className="w-full h-full object-cover" />
            ) : (
              <FiUser className="h-5 w-5 md:h-6 md:w-6 text-gray-600" />
            )}
          </div>
          <div>
            <h2 className="text-base md:text-lg font-semibold text-gray-800">Welcome,</h2>
            <p className="text-xs md:text-sm text-gray-600">{currentUser.displayName || currentUser.email}</p>
          </div>
          <FiChevronRight className="h-4 w-4 text-gray-400 ml-auto" />
        </div>
        <nav className="mb-6 md:mb-8">
          <ul className="space-y-2">
            {['opportunities'].map((item) => (
              <li key={item}>
                <a 
                  href={`#${item}`} 
                  className="group flex items-center space-x-3 text-gray-600 hover:text-gray-800 hover:bg-gray-100 p-3 rounded transition duration-150"
                  onClick={closeMobileMenu}
                >
                  {item === 'opportunities' ? <FiBriefcase className="h-5 w-5" /> : <FiFileText className="h-5 w-5" />}
                  <span className="font-medium capitalize">{item}</span>
                  <FiChevronRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition duration-150 ml-auto" />
                </a>
              </li>
            ))}
            {currentUser.role === 'admin' && (
              <li>
                <Link 
                  to="/admin-dashboard" 
                  className="group flex items-center space-x-3 text-gray-600 hover:text-gray-800 hover:bg-gray-100 p-3 rounded transition duration-150"
                  onClick={closeMobileMenu}
                >
                  <FiSettings className="h-5 w-5" />
                  <span className="font-medium">Admin Dashboard</span>
                  <FiChevronRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition duration-150 ml-auto" />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <button 
        onClick={() => {
          onLogout();
          closeMobileMenu();
        }}
        className="flex items-center justify-center space-x-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 w-full p-4 transition duration-150"
      >
        <FiLogOut className="h-5 w-5" />
        <span>Logout</span>
      </button>
    </>
  );

  return (
    <>
      {/* Mobile menu button */}
      <div className="md:hidden fixed top-4 right-4 z-50">
        <button
          onClick={toggleMobileMenu}
          className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
        >
          {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>

      {/* Mobile sidebar */}
      <div className={`md:hidden fixed inset-0 z-40 ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={closeMobileMenu}></div>
        <nav className="fixed top-0 right-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-l overflow-y-auto">
          {sidebarContent}
        </nav>
      </div>

      {/* Desktop sidebar */}
      <aside className="hidden md:flex md:flex-col md:w-64 md:bg-white md:shadow-md md:h-screen md:sticky md:top-0">
        {sidebarContent}
      </aside>
    </>
  );
};

export default Sidebar;