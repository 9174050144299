import { initializeApp } from "firebase/app";
import { 
  getAuth, 
  GoogleAuthProvider, 
  sendPasswordResetEmail, 
  sendEmailVerification,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithCredential,
  applyActionCode,
  updateProfile
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

let app;
let auth;
let db;
let storage;
let googleProvider;

export function initializeFirebase() {
  try {
    app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    db = getFirestore(app);
    storage = getStorage(app);
    googleProvider = new GoogleAuthProvider();
    console.log('Firebase initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase:', error);
  }
}

// Initialize Firebase immediately
initializeFirebase();

// Export Firebase instances and authentication functions
export { 
  auth, 
  db, 
  storage,
  googleProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithCredential,
  applyActionCode,
  updateProfile
};

// Helper function for password reset
export async function resetPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
}

// Helper function for email verification
export async function verifyEmail() {
  try {
    if (auth.currentUser) {
      await sendEmailVerification(auth.currentUser);
      console.log('Verification email sent successfully');
    } else {
      throw new Error('No user is currently signed in.');
    }
  } catch (error) {
    console.error('Error sending verification email:', error);
    throw error;
  }
}